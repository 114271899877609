<div class="blog-masonry-post">
  <div class="blog-masonry-thumb">
    <a [routerLink]="'/blog/'+blog.id+'/detail'">
      <img [src]="blog.img" alt="">
    </a>
  </div>
  <div class="blog-masonry-content">
    <div class="blog-meta">
      <ul class="list-wrap">
        <li><i class="far fa-clock"></i>{{ blog.date }}</li>
      </ul>
    </div>
    <h2 class="title">
      <a [routerLink]="'/blog/'+blog.id+'/detail'">{{ blog.title }}</a>
    </h2>
    <p>{{ stripHtmlTags(blog.article).substring(0, 200) }}...</p>
    <div class="content-bottom">
      <div class="blog-author">
        <a href="{{blog.author.link}}" target="_blank">
          <img [src]="blog.author.img" [alt]="blog.author.name">
          {{ blog.author.name }}
        </a>
      </div>
      <div class="read-more-btn">
        <a [routerLink]="'/blog/'+blog.id+'/detail'">Read More<i class="fas fa-arrow-right"></i></a>
      </div>
    </div>
  </div>
</div>
