import { Component, Input } from '@angular/core';
import {Blog} from "../../../../types/blog/blog-d-t";

@Component({
  selector: 'app-blog-standard',
  templateUrl: './blog-standard.component.html',
  styleUrl: './blog-standard.component.scss'
})
export class BlogStandardComponent {

  @Input() blog!: Blog;

  stripHtmlTags(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }
}
