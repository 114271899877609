import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  private consentKey = 'cookie-consent';
  private scriptLoaded = new BehaviorSubject<boolean>(false);
  public scriptLoaded$ = this.scriptLoaded.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  getConsent(): string | null {
    if (!isPlatformBrowser(this.platformId)) return null
    return localStorage.getItem(this.consentKey);
  }

  setConsent(consent: string): void {
    if (!isPlatformBrowser(this.platformId)) return
    localStorage.setItem(this.consentKey, consent);
    if (consent === 'all' || consent === 'necessary') {
      this.loadAdsenseScript();
    }
  }

   loadAdsenseScript(): void {
    if (!isPlatformBrowser(this.platformId)) return
    if (!(this.getConsent() === 'all' || this.getConsent() === 'necessary') || this.scriptLoaded.getValue()) {
      return;
    }
    this.scriptLoaded.next(true);
  }
}