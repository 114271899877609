import { Component, Input } from '@angular/core';
import {Blog} from "../../../../types/blog/blog-d-t";

@Component({
  selector: 'app-blog-masonry',
  templateUrl: './blog-masonry.component.html',
  styleUrl: './blog-masonry.component.scss'
})
export class BlogMasonryComponent {

  @Input() blog!: Blog;

  stripHtmlTags(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }
}
