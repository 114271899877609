import { Component, OnInit, Input, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { CookieConsentService } from '../shared/services/cookie-consent.service';

@Component({
  selector: 'app-adsense-ad',
  templateUrl: './adsense-ad.component.html',
  styleUrls: ['./adsense-ad.component.scss']
})
export class AdsenseAdComponent implements OnInit, OnDestroy {
  @Input() adHeight: number = 250; // Default ad height
  @Input() adWidth: number = 300;  // Default ad width
  @Input() isVerticalAds: boolean = false
  @Input() isSquareAds: boolean = false
  @Input() displayType: string = ''
  private subscription!: Subscription;

  constructor(@Inject(PLATFORM_ID) private platformId: object, private cookieConsentService: CookieConsentService, ) { }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.cookieConsentService.loadAdsenseScript();
    this.subscription = this.cookieConsentService.scriptLoaded$.subscribe(loaded => {
      if (loaded) {
        this.loadIframes("300", "250", environment.adsense.squareAdsKey, "square-ads");
        this.loadIframes("160", "600", environment.adsense.verticalAdsKey, "vertical-ads")
        this.loadIframes("728", "90", environment.adsense.horizontalAdsKey, "horizontal-ads");
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  loadIframes(
    width: string = "300", 
    height: string = "250", 
    key: string = "mock-key", 
    className: string = "iframe-container"
  ) {
    // Find all elements with the class 'iframe-container'
    const iframeContainers = document.querySelectorAll(`.${className}`);

    iframeContainers.forEach((iframeContainer: Element) => {
      // Create the iframe element
      const existingIframe = iframeContainer.querySelector('iframe');
      if (existingIframe) return
      const iframe = document.createElement('iframe');
      iframe.width = width;
      iframe.height = height;
      iframe.src = 'about:blank'; // Initially set to a blank page
      iframe.scrolling = 'no';

      iframe.onload = function () {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;

        if (iframeDocument) {
          iframeDocument.body.style.margin = '0';
        } else {
          console.error('Failed to access iframe document');
        }
      };

      // Append the iframe to the container
      iframeContainer.appendChild(iframe);

      // Inject the script dynamically into the iframe
      const script1 = document.createElement('script');
      script1.type = 'text/javascript';
      script1.innerHTML = `
        atOptions = {
          'key' : '${key}',
          'format' : 'iframe',
          'height' : '${height}',
          'width' : '${width}',
          'params' : {}
        };
      `;

      const script2 = document.createElement('script');
      script2.type = 'text/javascript';
      script2.src = `//www.highperformanceformat.com/${key}/invoke.js`;

      // Append the scripts to the iframe's content window
      iframe.contentWindow?.document.body.appendChild(script1);
      iframe.contentWindow?.document.body.appendChild(script2);
    });
  }
}