import {Component, OnInit} from '@angular/core';
import blog_data from '../../../data/blog-data';
import {Blog} from "../../../types/blog/blog-d-t";
import {UtilsService} from "../../../services/utils.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-blog-sidebar',
  templateUrl: './blog-sidebar.component.html',
  styleUrl: './blog-sidebar.component.scss'
})
export class BlogSidebarComponent implements OnInit {

  searchQuery = '';
  recent_posts : Blog[] = [...blog_data].slice(-3).reverse();

  constructor(
    public utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.searchQuery = params['search'] ? params['search'] : this.searchQuery;
    });
  }

  setSearchQuery() {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { page: 1, search: this.searchQuery },
        queryParamsHandling: 'merge',
        skipLocationChange: false,
      })
      .finally(() => {
        window.scrollTo(0, 0);
      });
  }
}
