<aside class="blog-sidebar">
  <div class="blog-widget">
    <h4 class="widget-title">Search</h4>
    <div class="sidebar-search">
      <form>
        <input type="text"
               name="search"
               placeholder="Search your keyword"
               [(ngModel)]="searchQuery"
               (ngModelChange)="setSearchQuery()"
        >
        <button type="submit" (click)="setSearchQuery()"><i class="fas fa-search"></i></button>
      </form>
    </div>
  </div>
  <!--  <div class="blog-widget">-->
  <!--    <h4 class="widget-title">Categories</h4>-->
  <!--    <div class="sidebar-cat-list">-->
  <!--      <ul class="list-wrap">-->
  <!--        <li><a href="#">Blockchain <span>05</span></a></li>-->
  <!--        <li><a href="#">Web Development <span>03</span></a></li>-->
  <!--        <li><a href="#">Cryptocurrency <span>06</span></a></li>-->
  <!--        <li><a href="#">Branding Design <span>05</span></a></li>-->
  <!--        <li><a href="#">Uncategorized <span>02</span></a></li>-->
  <!--      </ul>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="blog-widget">
    <h4 class="widget-title">Recent Posts</h4>
    <div class="rc-post-wrap">
      <div *ngFor="let rc of recent_posts" class="rc-post-item">
        <div class="thumb">
          <a [routerLink]="'/blog/'+rc.id+'/detail'">
            <img [src]="rc.img" alt="" style="width: 80px;height: 80px;object-fit: cover;">
          </a>
        </div>
        <div class="content">
          <h6 class="title">
            <a [routerLink]="'/blog/'+rc.id+'/detail'">{{ rc.title.slice(0, 40) }}..</a>
          </h6>
          <span class="date">{{ rc.date }}</span>
        </div>
      </div>
    </div>
  </div>

  <app-adsense-ad class="ad d-none d-lg-block" [isSquareAds]="true" [displayType]="'desktop'"></app-adsense-ad>
  <app-adsense-ad class="ad-mobile d-lg-none" [isSquareAds]="true" [displayType]="'mobile'"></app-adsense-ad>
</aside>
