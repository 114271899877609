import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe({
      next:event => {
        if (event instanceof NavigationEnd) {
          this.openMobileMenus = false;
        }
      }
    });
  }

  public openMobileMenus: boolean = false;

  public scrollToFragment(site:string ,fragment: string): void {
    this.router.navigate([site], {fragment: fragment});
    setTimeout(() => {
      const element = document.getElementById(fragment);
      if (element) {
        const offsetTop = element.offsetTop - 120;
        console.log('element offset', element, offsetTop);
        window.scrollTo({top: offsetTop, behavior: 'smooth'});
      }
    }, 100);
  }
}
