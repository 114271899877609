<div class="pb-50" id="tools">
  <div class="container">
      <div class="section-title text-center mb-50">
          <h2 class="title style2">Our tools</h2>
      </div>
      <div class="slider-area">
          <div class="row partner-slider1 swiper">
            <div class="swiper-wrapper p-0">
              <div *ngFor="let item of partner_data" class="col-lg-4 swiper-slide">
                  <div class="partner-card">
                      <div class="partner-card-img">
                          <img [src]="item.img" alt="img"><h3 [innerHTML]="item.title" class="text-start"></h3>
                      </div>
                      <p class="partner-card-text">{{item.text}}</p>
                      <a class="btn btn3" routerLink="{{item.link}}">EXPLORE</a>
                  </div>
              </div>
            </div>
            <div class="ns-swiper-dot-1"></div>
          </div>
      </div>
      <app-adsense-ad></app-adsense-ad>
  </div>
</div>
