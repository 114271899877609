<div class="overflow-hidden">
  <div class="container" id="faq">
    <div class="row gy-40 justify-content-between">
      <div class="col-xl-4 text-xl-start">
        <div class="section-title mb-50">
          <h2 class="title style2">Frequently Asked Questions</h2>
          <p class="sec-text">Do you have any kind of question? We're here to help.</p>
        </div>
        <div class="faq-thumb mt-60 d-none d-xl-block">
          <img src="/assets/img/update/normal/faq_1-1.png" alt="img">
        </div>
      </div>
      <div class="col-xxl-6 col-xl-8">
        <div class="accordion-area accordion" id="faqAccordion">

          <div *ngFor="let item of faq_data; let i = index" class="accordion-card" [class.active]="item.active">
            <div class="accordion-header" [attr.id]="'collapse-item-'+i">
              <button class="accordion-button" [class.collapsed]="!item.active" type="button" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#collapse-'+i" [attr.aria-expanded]="item.active ? 'true' : 'false'"
                      [attr.aria-controls]="'collapse-'+i"><span
                class="number">{{ i + 1 }}</span> {{ item.question }}
              </button>
            </div>
            <div [attr.id]="'collapse-'+i" class="accordion-collapse collapse" [class.show]="item.active"
                 [attr.aria-labelledby]="'collapse-item-'+i" data-bs-parent="#faqAccordion">
              <div class="accordion-body">
                <p class="faq-text" [innerHTML]="item.answer"></p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
